.header2 {
    font-family: "Sansation", sans-serif;
    background: #f1f1f1 url("../../images/header2.png") right no-repeat;
    padding: 0 10px;
    position: relative;
    overflow: hidden;

    @include mq(xs) {
        padding: 0 30px;
    }

    &__container {
        display: flex;
        position: relative;
        align-items: center;
        max-width: 1600px;
        padding: 30px 0;
        margin: auto;
        min-height: 220px;

        @include mq(xs) {
            padding: 30px 10px;
        }

        @include mq(md) {
            padding: 60px 10px;
        }

        @include mq(lg) {
            padding: 0 30px;
        }
    }

    &__title {
        color: white;
        font-size: 2rem;
        text-transform: uppercase;
        text-align: center;
        width: 100%;

        @include mq(xs) {
            font-size: 3rem;
            margin-left: -110px;
        }
    }
}

.languages2 {
    display: flex;
    position: absolute;
    justify-content: center;
    color: white;
    top: 0;
    right: 0;
    width: 100%;
    padding-left: 70px;

    @include  mq(xs) {
        width: auto;
        padding-left: 0;
        justify-content: flex-start;
    }

    @include  mq(lg) {
        width: 20%;
        justify-content: center;
        height: 100%;
    }

    &__container {
        display: flex;

        @include  mq(xs) {
            margin-top: 5px;
        }
    }

    &__language {
        display: flex;
        align-items: center;
        padding: 0 3px;
    }

    &__name {
        font-weight: bold;
        padding: 1px 3px 0 0;
    }

    &__image {
        padding: 0 0 0 3px;
    }
}
