.kontakt {
    display: flex;
    flex-direction: column;
    font-family: "Sansation", sans-serif;
    width: 100%;

    &__top {
        align-self: center;
        width: auto;

        &__container {
            display: grid;
            grid-template: auto / 1fr;
            gap: 60px;
            padding: 60px 0;

            @include mq(xs) {
                grid-template: auto / repeat(2, minmax(0, 1fr));
            }

            @include mq(lg) {
                grid-template: auto / repeat(4, minmax(0, 1fr));
            }
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &__image-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            min-height: 70px;
        }

        &__title {
            color: #3fb0ac;
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }

    &__form {
        display: flex;
        justify-content: center;
        background: #f1f1f1;
        padding: 60px 15px;

        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 1600px;
        }

        &__title {
            font-size: 1.5rem;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 10px;

            @include mq(xs) {
                font-size: 2.5rem;
            }
        }

        &__subtitle {
            margin-bottom: 40px;
            text-align: center;

            span {
                color: #3fb0ac;
            }
        }

        &__grid {
            display: grid;
            grid-template: auto / 1fr;
            gap: 20px 0;

            @include mq(xs) {
                gap: 20px;
            }

            @include mq(sm) {
                grid-template: auto / repeat(2, minmax(0, 1fr));
            }
        }

        &__wide {
            @include mq(sm) {
                grid-column: span 2;
            }
        }

        &__input-container {
            display: flex;
        }

        &__input {
            border-bottom: 2px solid #cccccc;
            width: 100%;
            padding: 10px 20px;

            @include mq(xs) {
                font-size: 1.4rem;
            }

            &:focus {
                box-shadow: none;
                outline: 0 none;
            }

            &::placeholder {
                color: black;
            }
        }

        &__consent {
            display: flex;
            align-items: center;

            input {
                margin-right: 10px;
                cursor: pointer;
            }

            label {
                cursor: pointer;
            }
        }

        &__submit {
            margin: auto;
            color: white;
            background: #3fb0ac;
            font-size: 1.3rem;
            text-align: center;
            text-transform: uppercase;
            padding: 20px 20px;
            transform: skewX(-10deg);

            @include mq(xs) {
                font-size: 1.6rem;
                padding: 20px 80px;
            }

            > div {
                display: block;
                transform: skewX(10deg);
            }
        }
    }
}
