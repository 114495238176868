@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts';
@import 'vars';
@import 'mixins';
@import 'cms';
@import 'posts';
@import 'search';

@import 'layout/page';
@import 'layout/header';
@import 'layout/header2';
@import 'layout/footer';
@import 'layout/nav';
@import 'layout/hamburger';

@import 'pages/home';
@import 'pages/home_slider';
@import 'pages/onas';
@import 'pages/kontakt';
