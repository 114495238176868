@font-face {
    font-family: 'Sansation';
    font-weight: normal;
    src: url("../font/Sansation_Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Sansation';
    font-weight: bold;
    src: url("../font/Sansation_Bold.ttf") format('truetype');
}

@font-face {
    font-family: 'Sansation';
    font-weight: 300;
    src: url("../font/Sansation_Light.ttf") format('truetype');
}
