$hamburgerHeight: 12;

.hamburger {
    transition: 0.2s ease;
    display: flex;
    z-index: 999;
    align-items: center;
    margin: 0 20px 0 0;

    @include mq(xs) {
        margin: 0 60px 0 0;
    }

    &__items {
        width: 50px;
        position: relative;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;
        display: block;
        height: 32px;
    }

    &__item {
        display: block;
        position: absolute;
        height: 6px;
        width: 100%;
        background: $primary;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        border-radius: 50px;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: #{$hamburgerHeight}px;
        }

        &:nth-child(3) {
            top: #{$hamburgerHeight * 2}px;
        }
    }

    &__text {
        text-align: center;
    }

    &--open .hamburger__item {
        &:nth-child(1) {
            transform: rotate(45deg);
            top: #{$hamburgerHeight}px;
            left: 0;
        }

        &:nth-child(2) {
            width: 0;
            opacity: 0;
        }

        &:nth-child(3) {
            transform: rotate(-45deg);
            top: #{$hamburgerHeight}px;
            left: 0px;
        }
    }
}
