.nav {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #484d4e;
    color: white;
    font-family: "Sansation", sans-serif;
    height: 100vh;
    width: 100vw;
    padding: 15px;
    top: 0;
    left: 0;
    z-index: 1000;

    transform-origin: 0 0;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

    &--active {
        transform: translate(0, 0);
    }

    @include mq(xs) {
        padding: 40px;
    }

    @include mq(sm) {
        max-width: 550px;
    }

    &__top {
        display: flex;
        align-self: center;
        justify-content: space-between;
        width: 100%;
        padding: 30px 30px;
        margin-bottom: 30px;
    }

    &__close {
        font-size: 3rem;
        font-weight: bold;
        cursor: pointer;
        align-self: flex-end;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        list-style: none;
        margin-bottom: 30px;
    }

    &__item {
        width: 100%;
        text-transform: uppercase;
        font-size: 1.1rem;
        transform: skewX(-10deg);

        &--selected {
            background: #3fb0ac;
            color: #484d4e;
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        padding: 15px 50px;

        span {
            display: block;
            transform: skewX(10deg);
        }
    }
}
