$footer-max-width: 1700px;

.footer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-family: "Sansation", sans-serif;

    @include mq(xl) {
        flex-direction: row;
    }

    &__container {
        color: white;
    }
}

.footer-left {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #22264b;
    background-size: auto;
    color: white;
    min-height: 300px;
    width: 100%;
    padding: 30px 10px;

    @include mq(xs) {
        background: #22264b url("../../images/footer_lines.png") no-repeat right;
        padding: 0;
    }

    @include mq(xl) {
        width: 75%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include mq(xs) {
            max-width: calc(0.75 * #{$footer-max-width});
            padding-left: 30px;
        }

        @include mq(xl) {
            flex-direction: row;
        }
    }
}

.footer-right {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    background: white;
    padding: 30px 0;

    @include mq(xl) {
        justify-content: flex-start;
        margin-left: -30px;
        padding: 0;
        transform: skewX(-10deg);
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: calc(0.25 * #{$footer-max-width});

        @include mq(xl) {
            transform: skewX(10deg);
        }
    }
}

.info {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @include mq(xs) {
        flex-direction: row;
    }

    @include mq(xl) {
        display: grid;
        grid-template: auto / auto auto;
        gap: 120px;
        margin-bottom: 0;
    }

    &__column {
        border-left: 5px solid white;
        padding-left: 30px;
        margin-right: 20px;
        margin-bottom: 20px;

        @include mq(xs) {
            max-width: calc(0.75 * #{$footer-max-width});
            padding-left: 30px;
            margin-bottom: 0;
        }

        @include mq(xl) {
            margin-right: 0;
        }
    }

    &__head {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 10px;
    }
}

.menu {
    &__container {
        position: relative;
        display: flex;
        margin-left: 42px;

        @include mq(xl) {
            margin-left: 200px;
        }
    }

    &__head {
        position: absolute;
        font-size: 2rem;
        font-weight: 600;
        top: 25px;
        left: -75px;
        transform: rotate(-90deg);
    }

    &__grid {
        display: grid;
        grid-template: auto / 1fr;
        gap: 5px 30px;

        @include mq(xs) {
            grid-template: auto / auto auto;
        }
    }
}

.copyright {
    display: flex;
    flex-direction: column;
    text-align: center;

    img {
        margin-bottom: 10px;
    }
}
