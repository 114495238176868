.slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__categories {
        display: grid;
        grid-template: auto / minmax(0, 1fr);
        gap: 10px;
        justify-content: center;
        padding: 20px 0;

        @include mq(sm) {
            grid-template: auto / repeat(2, minmax(0, 1fr));
            gap: 30px;
        }
    }

    &__category {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e4e3e8;
        color: #404040;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        padding: 15px 30px;
        transform: skewX(-10deg);
        cursor: pointer;

        @include mq(md) {
            font-size: 1.6rem;
        }

        &--active {
            background: #3fb0ac;
            color: white;
        }

        &__text {
            display: block;
            transform: skewX(10deg);
        }
    }

    &__dots {
        display: flex;
    }

    &__dot {
        background: #e4e3e8;
        cursor: pointer;
        height: 6px;
        width: 40px;
        margin: 15px 5px;

        &--active {
            background: #3fb0ac;
        }
    }

    &__container {
        position: relative;
        padding: 0 20px;

        @include mq(sm) {
            padding: 0 60px;
        }

        @include mq(lg) {
            padding: 0;
        }
    }

    &__arrow {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        padding-bottom: 120px;

        &--left {
            left: -15px;

            @include mq(sm) {
                left: 15px;
            }

            @include mq(lg) {
                left: -60px;
            }
        }

        &--right {
            right: -15px;

            @include mq(sm) {
                right: 15px;
            }

            @include mq(lg) {
                right: -60px;
            }
        }

        img {
            cursor: pointer;
        }
    }

    &__items {
        display: flex;
        justify-content: center;
        perspective: 1000px;
    }

    &__item {
        display: none;
        position: absolute;
        flex-direction: column;
        align-items: center;
        text-align: center;
        transition: transform 800ms ease;
        perspective: 1000px;

        a {
            transition: transform 1200ms ease;
        }

        &--active {
            position: static;
            display: flex;
            transform: none;

            a {
                transform: scale(1);
            }
        }

        &--side  {
            display: flex;

            a {
                transform: scale(0);
            }
        }

        &__monitor {
            position: relative;
            transition: transform 800ms ease;
            margin-bottom: 30px;
        }

        &__image-container {
            position: absolute;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            transition: transform 800ms ease;
            height: 100%;
            width: 100%;
            max-width: 93.5%;
            padding-top: 4.8%;
        }

        &__image {

        }

        &__link {
            color: #404040;
            font-size: 1.5rem;

            span {
                color: #3fb0ac;
            }
        }
    }
}
