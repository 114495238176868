.searchbar {
    position: relative;
    width: 100%;

    &__image-container {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        left: 30px;
    }

    input {
        color: white;
        border: 1px solid #656869;
        background: #484d4e;
        padding: 15px 30px 15px 80px;

        &::placeholder {
            color: white;
        }

        &:focus {
            box-shadow: none;
            outline: 0 none;
        }
    }
}
