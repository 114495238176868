.posts {
    display: flex;
    flex-direction: column;
    width: 100%;

    .post {
        display: flex;
        flex-direction: column;
        margin-bottom: 4rem;

        @include mq(sm) {
            flex-direction: row;
        }

        &__img-container {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            @include mq(sm) {
                width: 30%;
                margin-bottom: 0;
            }
        }

        &__img {
            max-width: 95%;
        }

        &__content {
            display: flex;
            flex-direction: column;

            @include mq(sm) {
                width: 70%;
            }
        }

        &__title {
            font-weight: bold;
        }

        &__short {
            flex-grow: 1;
        }

        &__more {
            margin: 0;
        }
    }
}
