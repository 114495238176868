.header {
    background: #f1f1f1;
    padding: 0 10px;
    position: relative;
    overflow: hidden;

    @include mq(xs) {
        padding: 0 30px;
    }

    &__image {
        display: none;
        position: absolute;
        top: 0;
        left: 750px;

        @include mq(lg) {
            display: block;
        }

        @include mq("1792") {
            left: auto;
            right: 0;
        }
    }

    &__container {
        display: flex;
        position: relative;
        align-content: center;
        max-width: 1600px;
        padding: 30px 0;
        margin: auto;

        @include mq(xs) {
            padding: 30px 10px;
        }

        @include mq(md) {
            padding: 60px 10px;
        }

        @include mq(lg) {
            padding: 60px 30px;
        }
    }

    &__contact {
        display: none;
        z-index: 999;

        @include mq(md) {
            display: flex;
        }

        &__item {
            display: flex;
            align-items: center;
            margin-right: 30px;
        }

        &__icon {
            margin-right: 10px;
        }
    }
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 999;

    @include mq(xs) {
        display: block;
        margin-right: 40px;
        width: auto;
    }

    @include mq(lg) {
        margin-right: 120px;
    }
}

.header-box {
    margin: 0 0 50px 0;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 1600px;
        margin: auto;
    }

    &__top {
        z-index: 999;
        font-family: 'Nunito', sans-serif;
        line-height: 1.2;
        padding: 10px 0;
        margin-bottom: 30px;

        @include mq(md) {
            background: url("../../images/header_box.png");
            height: 361px;
            width: 728px;
            padding: 65px 50px;
            margin-bottom: 40px;
        }

        h1 {
            color: #202126;
            font-size: 1.6rem;
            margin-bottom: 30px;
            font-weight: 300;
            text-align: center;

            @include mq(xs) {
                font-size: 2.4rem;
                text-align: left;
            }

            @include mq(md) {
                font-size: 2.7rem;
            }
        }

        h2 {
            color: #848484;
            font-size: 1.6rem;
            text-align: center;

            @include mq(xs) {
                text-align: left;
            }
        }

        strong {
            font-weight: 700;
        }
    }

    &__bottom {
        z-index: 999;
        width: 100%;
        max-width: 728px;

        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include mq(md) {
                flex-direction: row;
                justify-content: space-around;
            }

            img {
                padding: 10px 0;
            }
        }
    }
}

.languages {
    display: flex;
    position: absolute;
    justify-content: center;
    font-family: "Sansation", sans-serif;
    top: 0;
    right: 0;
    width: 100%;
    padding-left: 70px;

    @include  mq(xs) {
        width: auto;
        padding-left: 0;
        justify-content: flex-start;
    }

    @include  mq(lg) {
        width: 20%;
        justify-content: flex-start;
    }

    &__container {
        display: flex;

        @include  mq(xs) {
            margin-top: 5px;
        }
    }

    &__language {
        display: flex;
        align-items: center;
        padding: 0 3px;
    }

    &__name {
        font-weight: bold;
        padding: 1px 3px 0 0;
    }

    &__image {
        padding: 0 0 0 3px;
    }
}
