.home-page-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "Sansation", sans-serif;
    padding: 40px 20px;

    @include mq(sm) {
        padding: 40px 10px;
    }

    &__head {
        font-size: 2.5rem;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
}

.home-page-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #00b1ac;
    font-family: "Sansation", sans-serif;
    color: white;
    padding: 40px 20px;

    @include mq(lg) {
        background: #00b1ac url("../../images/home_center.png") no-repeat center;
    }

    &__head {
        font-size: 2.5rem;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 40px;
    }

    &__columns {
        display: grid;
        grid-template: auto / 1fr;
        gap: 10px;
        margin-bottom: 20px;

        @include mq(sm) {
            grid-template: auto / 1fr 1fr;
            gap: 50px;
        }
    }

    &__column {
        font-size: 1.2rem;
        text-align: justify;
        width: 100%;
        max-width: 600px;
    }

    &__bottom {
        color: #404040;
        font-weight: 700;
    }
}

.home-page-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Sansation", sans-serif;
    padding: 40px 20px;

    &__head {
        font-size: 1.5rem;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 30px;

        @include mq(xs) {
            font-size: 2.5rem;
        }
    }
}

.home-page-image {
    display: flex;
    justify-content: center;
}

.websites {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    color: #404040;
    font-family: "Sansation", sans-serif;

    @include mq(xl) {
        display: grid;
        grid-template: auto / auto auto auto;
    }

    @include mq(xxl) {
        gap: 60px;
    }

    &__item {
        position: relative;
        padding: 20px 10px;

        @include mq(xs) {
            background: url("../../images/home_box.png");
            height: 410px;
            width: 421px;
            padding: 45px 20px 0 50px;
            margin: 20px;
        }
    }

    &__title {
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.1;
        margin-bottom: 10px;

        @include mq(xs) {
            font-size: 2.5rem;
        }
    }

    &__text {
        font-size: 0.9rem;
        text-align: justify;
    }

    &__details {
        position: absolute;
        color: #3fb0ac;
        text-transform: uppercase;
        right: 55px;
        bottom: -7px;
    }
}
